.profile-settings-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  .profile-image-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
.my-sessions-page {
    padding: 30px 0;
  }
  
  .session-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
  }
  
  .session-card .badge {
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: 20px;
  }
  
  .session-card .btn {
    margin-top: 10px;
  }
  
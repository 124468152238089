/* src/pages/Booking.css */

.booking-page {
    padding-top: 20px;
    padding-bottom: 50px;
  }
  
  .booking-cover {
    background-image: url('/public/images/contact_cover.png'); /* Replace with your cover image path */
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    padding: 60px 0;
    position: relative;
  }
  
  .booking-cover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Black overlay */
    z-index: 1;
  }
  
  .booking-heading {
    position: relative;
    z-index: 2;
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
  }
  
  .content-spacing {
    margin-top: 40px; /* Space between the cover and content */
  }
  
  .booking-form {
    margin-bottom: 20px;
  }
  
  .booking-form .form-control {
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .booking-form button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .booking-form button:hover {
    background-color: #0056b3;
  }
  
  .booking-info {
    margin-top: 40px;
  }
  
  .booking-info h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .booking-info p {
    font-size: 1.125rem;
    line-height: 1.6;
  }
  
/* src/pages/MyCourses.css */

.my-courses-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  .course-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .course-card {
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .course-card:hover {
    transform: translateY(-5px);
  }
  
  .course-card img {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  
  .course-title-link {
    text-decoration: none;
    color: #007bff;
  }
  
  .course-title-link:hover {
    text-decoration: underline;
  }
  
  .purchase-info {
    margin-top: 10px;
    font-style: italic;
    color: #666;
  }
  
header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000; /* Ensure header is on top */
  position: relative; /* Ensure z-index is applied */
}

.status-bar {
  width: 100%;
  background-color: #0056b3;
  color: white;
  padding: 5px 0;
}

.header-main {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 70px;
  position: relative;
  overflow: visible; /* Allow dropdown to extend beyond header */
  z-index: 1001; /* Maintain the stacking order */
}

.menu {
  display: flex;
  gap: 20px;
  justify-content: center;
  white-space: nowrap; /* Prevent text from wrapping */
}

.menu a,
.dropdown-label {
  text-decoration: none;
  color: black;
  font-weight: bold;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.menu a::after,
.dropdown-label::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: black;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.menu a:hover::after,
.dropdown-label:hover::after {
  width: 100%;
  left: 0;
  background: black; /* Underline color on hover */
}

.dropdown-wrapper {
  position: relative;
  z-index: 1002; /* Ensure dropdown is on top */
}

.dropdown-wrapper:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1003; /* Ensure dropdown is on top of everything */
  padding: 10px 0;
  min-width: 200px;
}

.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.logo {
  flex: 1;
  display: flex;
  justify-content: center;
}

.logo img {
  height: 60px; /* Set logo height to 100px */
  width: auto;
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

.auth-buttons .sign-in,
.auth-buttons .register {
  padding: 5px 15px;
  border-radius: 20px;
  border: 1px solid #0056b3;
  background: white;
  cursor: pointer;
}

.auth-buttons .register {
  background: #0056b3;
  color: white;
}

.welcome-text {
  font-size: 1rem;
  color: black;
  font-weight: 500;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.avatar-wrapper:hover .custom-dropdown {
  display: block;
}

.custom-dropdown {
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1004;
  width: 200px;
  font-size: 0.9rem !important;
}

.user-info {
  text-align: left;
  margin-bottom: 10px;
}

.dropdown-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-links li {
  padding: 8px 0;
  cursor: pointer;
}

.dropdown-links li a {
  text-decoration: none;
  color: #333;
}

.dropdown-links li:hover {
  background-color: #f0f0f0;
}

.dropdown-links hr {
  margin: 10px 0;
}

@media (max-width: 768px) {
  .header-main {
    flex-direction: column;
    align-items: flex-start;
    height: auto; /* Allow flexible height on small screens */
  }

  .menu {
    flex-direction: column;
    text-align: center;
  }
  
  .language-selector {
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }

  .language-selector:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .language-selector option {
    background-color: white;
    color: black;
  }

  .status-bar {
    padding: 10px 0;
  }

}

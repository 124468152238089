.dashboard-layout {
  display: flex;
  height: 100vh;
}

.dashboard-content {
  flex-grow: 1;
  padding: 20px;
  margin-left: 250px; /* Space for sidebar */
  background-color: #f8f9fa;
}

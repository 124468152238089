/* src/pages/About.css */

.about-page {
    padding-top: 60px;
  }
  
  .about-text h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .about-text p {
    font-size: 1.125rem;
    color: #6c757d;
  }
  
  .mission-section {
    background-color: #e9f5ff; /* Light blue background for mission section */
    padding: 50px 0;
  }
  
  .mission-text h4 {
    font-size: 1.2rem;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .mission-text h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .mission-text p {
    font-size: 1.125rem;
    color: #6c757d;
  }
  
  .testimonials-section {
    background-color: #f8f9fa; /* Light gray background for testimonials */
    padding: 50px 0;
  }
  
  .testimonial {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .testimonial p {
    font-size: 1.125rem;
    font-style: italic;
    margin-bottom: 20px;
    color: #495057;
  }
  
  .testimonial h6 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .testimonial small {
    color: #6c757d;
  }
  
  .testimonial a {
    color: #007bff;
    text-decoration: none;
  }
  
  .testimonial a:hover {
    text-decoration: underline;
  }
  
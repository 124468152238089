/* src/components/LeaderboardSection.css */

.leaderboard-section {
  padding: 30px 0;
  background-color: #111; /* Dark background color */
  color: white; /* White text color */
  background-image: url('/public/images/leader_board.png'); /* Add background image */
  background-size: cover; /* Cover the entire section */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent image from repeating */
}

.leaderboard-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.leaderboard-subtitle {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 30px;
}

.leaderboard-table {
  width: 100% !important;
  border-collapse: separate; /* Allows spacing between rows */
  border-spacing: 0 10px; /* Space between rows */
}

.leaderboard-table th, .leaderboard-table td {
  text-align: center;
  vertical-align: middle;
  border: none; /* Remove borders */
  background-color: #007bff !important; /* Match the background color */
  color: white !important; /* Ensure text color is white */
}

.leaderboard-table th {
  font-weight: bold;
  font-size: 1rem;
}

.leaderboard-table td {
  font-size: 1rem;
}

.leaderboard-table tr {
  border-radius: 30px; /* Rounded corners for each row */
  overflow: hidden; /* Ensure the rounded corners are visible */
}

.leaderboard-table tr td:first-child, .leaderboard-table tr th:first-child {
  border-top-left-radius: 30px; /* Round top-left corner */
  border-bottom-left-radius: 30px; /* Round bottom-left corner */
}

.leaderboard-table tr td:last-child, .leaderboard-table tr th:last-child {
  border-top-right-radius: 30px; /* Round top-right corner */
  border-bottom-right-radius: 30px; /* Round bottom-right corner */
}

.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

/* src/superadmin/pages/Categories.css */

.categories-page {
    padding-top: 20px;
  }
  
  .categories-page h2 {
    margin-bottom: 20px;
  }
  
  .table {
    margin-top: 20px;
  }
  
  .modal-title {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
/* src/components/CategorySection.css */

.category-section {
    padding: 30px 0;
    text-align: center;
  }
  
  .category-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .category-card {
    height: 150px !important;
    padding: 20px;
    border: none;
    border-radius: 8px;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }
  
  .category-card:hover {
    transform: translateY(-5px);
  }
  
  .category-icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .bg-light-purple {
    background-color: #f3e6ff !important;
  }
  
  .bg-light-green {
    background-color: #e6fff3 !important;
  }
  
  .bg-light-orange {
    background-color: #fff3e6 !important;
  }
  
  .bg-light-red {
    background-color: #ffe6e6 !important;
  }
  
  .bg-light-grey {
    background-color: #f0f0f0 !important;
  }
  
  .bg-light-pink {
    background-color: #ffe6f3 !important;
  }
  
  .category-footer {
    margin-top: 30px;
    font-size: 1rem;
  }
  
  .category-footer a {
    color: #007bff;
    text-decoration: none;
  }
  
  .category-footer a:hover {
    text-decoration: underline;
  }
  
/* src/pages/Wishlist.css */

.wishlist-page {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  .wishlist-course-list {
    display: flex;
    flex-direction: column;
  }
  
  .course-card {
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .course-card:hover {
    transform: translateY(-5px);
  }
  
  .course-card img {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  
  .course-card .details-btn {
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  }
  
  .course-card .details-btn:hover {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .course-level {
    font-size: 0.875rem;
    font-weight: bold;
    color: white;
    background-color: #007bff; /* Customize this color as needed */
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .session-type {
    font-size: 0.875rem;
    font-weight: bold;
    color: white;
    background-color: #6c757d; /* Customize this color as needed */
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 5px; /* Adjust margin as needed */
  }
  
  .price {
    font-size: 1.25rem;
    font-weight: bold;
    color: #28a745; /* Customize this color as needed */
  }
  
/* src/pages/Contact.css */

.contact-page {
  padding-top: 10px;
}

.contact-cover {
  background-image: url('/public/images/contact_cover.png'); /* Replace with your cover image path */
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 60px 0;
  position: relative;
}

.contact-cover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Black overlay */
  z-index: 1;
}

.contact-heading {
  position: relative;
  z-index: 2;
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
}

.content-spacing {
  margin-top: 40px; /* Space between the cover and content */
}

.contact-info h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 30px;
}

.contact-details {
  list-style: none;
  padding-left: 0;
  margin-bottom: 30px;
}

.contact-details li {
  font-size: 1.125rem;
  margin-bottom: 10px;
  text-align: center;
}

.contact-image {
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.map-container {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
}

.social-icons {
  margin-top: 20px;
}

.social-icons a {
  font-size: 2rem;
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-icons a.facebook { color: #3b5998; }
.social-icons a.twitter { color: #1da1f2; }
.social-icons a.instagram { color: #c32aa3; }
.social-icons a.linkedin { color: #0077b5; }

.social-icons a:hover {
  opacity: 0.8;
}

.form-control {
  border-radius: 5px;
  margin-bottom: 15px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

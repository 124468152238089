.sidebar {
  width: 250px;
  background-color: #343a40;
  color: white;
  padding: 20px;
  height: 100vh;
  position: fixed;
  overflow-y: auto; /* Enable vertical scrolling */
}
.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #495057; /* Customize scrollbar thumb color */
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #343a40; /* Customize scrollbar track color */
}

  .sidebar h2 {
    color: white;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .sidebar-links {
    list-style: none;
    padding: 0;
  }
  
  .sidebar-links li {
    margin: 10px 0;
  }
  
  .sidebar-links li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    display: block;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .sidebar-links li a:hover, .sidebar-links li.active a {
    background-color: #495057;
  }
  
  .sidebar-links .logout-link a {
    color: #f8d7da;
  }

  .content-management-header {
    cursor: pointer;
    padding: 10px;
    color: white;
    font-weight: bold;
    display: block;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .content-management-header:hover {
    background-color: #495057;
  }
  
  .content-management-links {
    list-style: none;
    padding-left: 20px;
  }
  
  .content-management-links li {
    margin: 5px 0;
  }
  
  
/* src/components/PopularCoursesSection.css */

.popular-courses-section {
    padding: 30px 0;
    text-align: center;
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .categories {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .category-link {
    font-size: 1rem;
    font-weight: bold;
    color: black;
    margin: 0 10px;
  }
  
  .courses-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .course-card {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 20px !important;
    overflow: hidden;
    transition: transform 0.2s;
    cursor: pointer;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .course-card:hover {
    transform: translateY(-5px);
  }
  
  .course-card img {
    height: 250px;
    object-fit: cover;
  }
  
  .course-card .card-body {
    text-align: left;
  }
  
  .course-card .course-price {
    color: #007bff;
    font-weight: bold;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .nav-button {
    font-size: 2rem;
    color: black;
  }
  
  .course-card .card-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Ensure long titles don't affect layout */
}
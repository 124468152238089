/* PrivacyPolicy.css */

.privacy-policy {
    padding: 50px 0;
    background-color: #f8f9fa;
  }
  
  .privacy-policy-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .privacy-policy-intro {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 30px;
  }
  
  .privacy-policy-section-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 30px;
    color: #444;
  }
  
  .privacy-policy-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
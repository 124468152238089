/* src/components/MyCourseLearning.css */

.video-section {
    height: 350px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    overflow: hidden;
}

.progress-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: conic-gradient(#98b9dd calc(var(--progress) * 1%), #e9ecef 0%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #070707;
    font-weight: bold;
    font-size: 1rem;
    border: 2px solid #007bff;
    margin-right: 10px;
}

.progress-label {
    font-size: 1rem;
    font-weight: bold;
    color: #007bff;
    padding-right: 10px;
}

.lesson-title {
    text-align: left;
    padding-left: 15px;
    margin-top: 20px;
    font-size: 1.5rem;
    font-weight: bold;
}

.lesson-description {
    text-align: left;
    padding-left: 15px;
    margin-top: 10px;
    font-size: 1rem;
    padding-bottom: 50px;
}

/* src/components/Footer.css */

.footer-section {
  background-color: #0056b3;
  color: white;
  padding: 0; /* Remove padding to ensure no extra space */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 2dvb 0; /* Add some padding vertically */
}

.footer-column {
  flex: 1; /* Equal width for all columns */
  padding: 15px; /* Internal padding for content spacing */
  box-sizing: border-box; /* Ensure padding doesn't affect the border size */
  text-align: left; /* Align content to the left */
}

.footer-column:not(:last-child) {
  border-right: none; /* Remove the right border for all but the last column */
}

.footer-logo img {
  width: 80px;
  height: 90px; /* Adjust the logo size */
  margin: 0 auto; /* Center logo horizontally */
  display: block;
}

.footer-column h5 {
  font-size: 1.1rem;
  margin-bottom: 10px; /* Adjust margin */
}

.footer-links, .footer-social {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li, .footer-social li {
  margin-bottom: 5px; /* Adjust margin for compact layout */
}

.footer-links a, .footer-social a {
  color: white;
  text-decoration: none;
}

.footer-links a:hover, .footer-social a:hover {
  text-decoration: underline;
}

.footer-social {
  display: flex;
  gap: 10px;
}

.payment-methods {
  display: flex;
  gap: 10px; /* Adjusted gap for compact layout */
  margin-top: 10px; /* Added margin between social icons and payment methods */
}

.payment-methods img {
  width: 50px; /* Standard icon size */
  height: 40px;
}

.payment-methods img[src*="visa.png"] {
  width: 50px;  /* Custom width for Visa */
  height: 42px;
}

.payment-methods img[src*="mastercard.png"] {
  width: 50px;  /* Custom width for MasterCard */
  height: 42px;
}

.footer-section hr {
  border-color: rgb(245, 241, 241);
  margin: 10px 0; /* Adjust margin around the horizontal line */
}

.footer-section p {
  margin: 0;
  font-size: 0.8rem; /* Slightly smaller text for compact design */
}

.swiss-flag {
  width: 20px;
  height: auto; /* Maintain aspect ratio */
  margin-left: 5px;
}

/* Ensure the contact us section content is aligned to the left */
.footer-contact {
  text-align: left; /* Ensure text alignment to the left */
}

.footer-contact h5, .footer-contact p {
  text-align: left; /* Ensure all child elements are aligned to the left */
  margin: 0; /* Remove any default margins that may cause centering */
}

/* src/components/PortfolioSection.css */

.portfolio-section {
    padding: 50px 0;
    text-align: center;
    background-color: white; /* White background */
  }
  
  .portfolio-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .portfolio-subtitle {
    font-size: 1rem;
    color: #6c757d; /* Grey color for subtitle */
    margin-bottom: 40px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .metrics-row {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  
  .metric-col {
    margin-bottom: 20px;
  }
  
  .metric-value {
    font-size: 3.5rem;
    font-weight: bold;
    color: #007bff; /* Blue color for metrics */
    margin-bottom: 5px;
  }
  
  .metric-label {
    font-size: 1rem;
    font-weight: normal;
    color: #343a40; /* Dark grey color for labels */
  }
  
/* Hero Section Styles */
.hero-section {
  background-image: url('/public/images/hero_section.png'); /* Corrected path */
  background-size: cover; /* Ensure the image covers the entire section */
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  text-align: center;
  position: relative;
  z-index: 1; /* Set lower z-index to ensure it stays behind the dropdown */
  padding-bottom: 120px;
}

.hero-title {
  color: black;
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0;
}

.hero-subtitle {
  color: black;
  font-weight: normal;
  font-size: 1.25rem;
  margin: 20px 0;
}

/* Custom Search Bar Styles */
.search-bar-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.search-bar-input {
  width: 100%;
  height: 50px; /* Set height to 50px */
  border-radius: 30px !important; /* Set border-radius to 30px */
  padding: 10px 50px 10px 20px;
  background-color: #f0ecf4;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  position: relative; /* Keep it relative for the icon positioning */
  z-index: 2; /* Ensure the input stays above suggestions */
}

.search-bar-input::placeholder {
  color: #666;
  opacity: 1; /* Make sure the placeholder is fully visible */
  transition: opacity 0.5s ease-in-out; /* Smooth transition when placeholder changes */
}

.search-bar-input.fade-placeholder::placeholder {
  opacity: 0; /* Fade out the placeholder */
}

.search-bar-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem;
  color: #666;
  z-index: 3; /* Ensure the icon stays above suggestions */
}

/* Suggestions Styles */
.suggestions-container {
  margin-top: 5px;
  position: absolute;
  width: 100%;
  z-index: 1; /* Ensure suggestions are below the search bar */
}

.suggestions-title {
  font-size: 1rem;
  color: black;
  margin-bottom: 10px;
  text-align: left;
  padding: 5px;
  border-radius: 8px 8px 0 0;
}

.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0 0 8px 8px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative; /* Ensure suggestions stay below the search bar */
}

.suggestion-item {
  padding: 1px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

/* src/superadmin/pages/DashboardMain.css */

.dashboard-main {
    padding-top: 20px;
  }
  
  .dashboard-card {
    text-align: center;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-card .card-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .dashboard-card .card-text {
    font-size: 1.25rem;
    font-weight: 500;
  }
  
.login-page {
    padding-top: 25px;
    min-height: 50vh;
    display: flex;
    align-items: center;
    padding-bottom: 50px;
  }
  
  .image-section {
    position: relative;
  }
  
  .login-image {
    border-radius: 8px;
    max-width: 100%;
  }
  
  .image-caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
  }
  
  .image-caption h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .image-caption p {
    font-size: 1.2rem;
  }
  
  .form-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-container {
    width: 100%;
    max-width: 400px;
    text-align: left;
  }
  
  .welcome-text {
    font-size: 0.9rem;
    margin-bottom: 10px;
    padding-right: 10px;
  }
  
  .buttons-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .login-btn {
    margin-right: 10px;
    width: 130px;
  }
  
  .register-btn {
    width: 130px;
  }
  
  .info-text {
    margin-bottom: 20px;
  }
  
  .password-container {
    position: relative;
  }
  
  .password-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .submit-btn {
    width: 100%;
    margin-top: 20px;
  }
  
  .forgot-password-link {
    text-decoration: none;
    font-size: 0.9rem;
  }
  
.avatar-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  table {
    margin-top: 20px;
  }
  
  thead th {
    text-align: center;
  }
  
  tbody td {
    text-align: center;
    vertical-align: middle;
  }
  
.courses-page {
  padding-top: 30px;
  padding-bottom: 80px;
}

.filters-section {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filters-section h4 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #007bff; /* Theme color */
}

.courses-section h4 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.course-list .course-card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.course-list .course-card:hover {
  transform: translateY(-5px);
}

.course-card img {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.course-card .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.course-card .btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.course-level {
  font-size: 0.875rem;
  font-weight: bold;
  color: white;
  background-color: #007bff; /* Customize this color as needed */
  padding: 5px 10px;
  border-radius: 5px;
}

.enroll-btn {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.enroll-btn:hover {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.session-type {
  font-size: 0.875rem;
  font-weight: bold;
  color: white;
  background-color: #6c757d; /* Customize this color as needed */
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 5px; /* Adjust margin as needed */
}

.price {
  font-size: 1.25rem;
  font-weight: bold;
  color: #28a745; /* Customize this color as needed */
}
